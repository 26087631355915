import React from "react"
import { graphql } from "gatsby"
import { Container } from "../../components/layout/Container";
import { Helm } from "../../components/layout/Helm";
import { Brand } from "../../components/navigation/Brand";
import { Footer } from "../../components/layout/Footer";
import { Button, ButtonColour, ButtonSize } from "../../components/layout/Button";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
    }
}

export default ({ data }: PageData) => {
    const title = "Thanks for reaching out!";
    const subtitle = "We'll get back to you as soon as we can.";

    return (
        <>
            <Helm pageTitle={"Thanks"} description={data.site.siteMetadata.description} />
            <Container className="thanks-container text-center ">
                <Brand />
                <h1>{title}</h1>
                <p>{subtitle}</p>
                <Button
                    buttonConfig={{
                        id: "blueGradient",
                        colour: ButtonColour.BLUE,
                        ctaLabel: "Back To Contact",
                        ctaLink: "/contact",
                        size: ButtonSize.LARGE,
                    }}
                />
            </Container>
            <Footer />
        </>
    );
};


export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`